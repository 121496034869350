<template>
  <div>
    <router-view />
  </div>
</template>

<script>
import axios from "axios";
// import { rand } from "../../api/http";
export default {
  data() {
    return {};
  },
  components: {
   
  },
  mounted() {
    // 资源中心数据上报
    // axios.get("http://access.rujiaowang.net/GetLogServer").then((res) => {
    //   var differenctime = res.data.result.net_ts - Date.parse(new Date()) / 1000;
    //   localStorage.setItem(
    //     "testpaper_traceid",
    //     JSON.stringify(
    //       `${JSON.parse(localStorage.getItem("loginInfo")).user_id}` +
    //         `${Number(Date.parse(new Date())) / 1000 + differenctime}` +
    //         `${rand(1000, 9999)}`
    //     )
    //   );
    // });
  },
  methods: {
    
  },
};
</script>

<style lang='less'>
</style>